import React from "react";
import TopPart from "./TopPart";

function Home() {
  return (
      <div className="home">
        <TopPart />
      </div>
  );
}

export default Home;
